footer {
  flex-shrink: 0;

  .list-inline-dots > li:not(:last-child) {
    &:after {
      content: '\00b7';
      margin-left: 0.5rem;
      // align-self: center;
      // margin: 1rem 0;
    }
  }
  // .list-middot > li:not(:last-child)::after {
  //   content: '\00b7';
  // }
}
