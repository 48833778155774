.stamp {
  display: inline-block;
  min-width: px2rem(40px);
  height: px2rem(40px);
  padding: 0 0.25rem;
  font-size: $font-size-base;
  font-weight: 600;
  line-height: px2rem(40px);
  color: $text-muted;
  text-align: center;
  background: theme-color-lightest($text-muted);
  border-radius: 3px;

  .icon {
    font-size: 1.25rem;
  }
}
