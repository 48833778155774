/*
 * This css is used when printing or exporting to pdf via browser
 */
@media print {
  /* full page width */
  #page-content > div.container {
    width: 100%;
    max-width: 100%;
  }

  /* hide header, navbar & footer */
  div.bg-primary,
  header.header,
  .navbar,
  footer.bg-light {
    height: 0;
    display: none;
  }
  footer > div {
    display: none;
    border: 3px solid gold;
  }
  /* hide buttons */
  .ant-btn,
  .euiButton {
    display: none;
  }

  /* hide datepicker buttons */
  .euiButtonEmpty,
  .euiSuperDatePicker__prettyFormatLink {
    display: none;
  }
  [data-test-subj='superDatePickerShowDatesButton'] {
    width: 100%;
    clear: both;
    text-align: right;
  }

  /* format breadcrumb 
    *  - only show active
    *  - remove `/`
    * - remove underline
    */
  li.breadcrumb-item:not(.active) {
    display: none;
  }
  li.breadcrumb-item.active::before {
    content: '';
  }
  li.breadcrumb-item.active {
    text-decoration: none;
  }

  /*
  * No pagebreaks inside widgets
  */
  div.react-grid-item {
    page-break-inside: auto;
  }
}
