// stylelint-disable declaration-no-important

.hover-shadow-sm:hover {
  box-shadow: $box-shadow-sm !important;
}

.hover-shadow:hover {
  box-shadow: $box-shadow !important;
}

.hover-shadow-lg:hover {
  box-shadow: $box-shadow-lg !important;
}

.hover-shadow-none:hover {
  box-shadow: none !important;
}
