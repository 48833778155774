textarea {
  &[cols] {
    height: auto;
  }
}

.form-label {
  display: block;
  margin-bottom: 0.375rem;
  font-size: $h5-font-size;
  font-weight: 600;
}

.form-group {
  display: block;

  &.row {
    display: flex;
  }
}

.form-control {
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
  }

  &:disabled,
  &.disabled {
    color: $text-muted;
    user-select: none;
  }
}

.form-control-light {
  background-color: $min-black;
  border-color: $min-black;
}

.form-footer {
  margin-top: 2rem;
}

.form-fieldset {
  padding: 1rem;
  margin-bottom: 1rem;
  background: $min-black;
  border: 1px solid $border-color;
  border-radius: $border-radius;
}

.form-required {
  color: $red;
}

.custom-control-label::before {
  background: transparent;
  border: 1px solid $border-color;
}

/**
Form help
 */
.form-help {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  text-align: center;
  line-height: 1rem;
  color: $text-muted;
  background: $gray-100;
  border-radius: 50%;
  font-size: px2rem(12px);
  transition: 0.3s background-color, 0.3s color;
  text-decoration: none;
  cursor: pointer;
  user-select: none;

  &:hover,
  &[aria-describedby] {
    background: $primary;
    color: #fff;
  }
}
