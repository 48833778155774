.card {
  // margin-bottom: 1.5rem;
  background-color: var(--white);
  // overflow: hidden;
  // border-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
  // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.card-link {
  color: inherit;

  &:hover {
    color: inherit;
    text-decoration: none;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.08);
  }
}

.card-btn {
  padding: $card-spacer-y $card-spacer-x;
  text-align: center;
  background: rgba($primary, 0.02);
  transition: 0.3s background;

  &:hover {
    text-decoration: none;
    background: rgba($primary, 0.06);
  }
}

.card-cover {
  position: relative;
  padding: $card-spacer-y $card-spacer-x;
  background: #666 no-repeat center/cover;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    background: $light-black;
  }

  &:first-child,
  &:first-child::before {
    border-radius: $border-radius $border-radius 0 0;
  }
}

.card-cover-blurred {
  &::before {
    backdrop-filter: blur(2px);
  }
}

.card-footer {
  background: transparent;

  &:last-child {
    border-radius: 0 0 $border-radius $border-radius;
  }
}

.card-header {
  display: flex;
  align-items: center;
  min-height: 3.5rem;
  background: transparent;
}

.card-header-tabs {
  margin: (-$card-spacer-y) (-$card-spacer-x);
}

.card-progress {
  height: 4px;

  &:last-child {
    border-radius: 0 0 2px 2px;
  }

  &:first-child {
    border-radius: 2px 2px 0 0;
  }
}

.card-meta {
  color: $text-muted;
}

.card-title {
  display: block;
  font-size: $h4-font-size;
  font-weight: 500;
  margin: 0 0 1.5rem;
  line-height: 1;
  color: inherit;

  @at-root a#{&}:hover {
    color: inherit;
  }

  .card-header & {
    margin: 0;
  }
}

.card-body {
  > :last-child {
    margin-bottom: 0;
  }
}

.card-body-scrollable {
  overflow: auto;
}

/**
Card optinos
 */
.card-options {
  top: 1.5rem;
  right: 0.75rem;
  margin-left: auto;
  display: flex;
}

.card-options-link {
  display: inline-block;
  min-width: 1rem;
  margin-left: 0.25rem;
  color: $text-muted-light;
}

/**
Card status
 */
.card-status-top {
  position: absolute;
  height: 3px;
  border-radius: $card-border-radius $card-border-radius 0 0;
  top: 0;
  right: 0;
  left: 0;
}

.card-status-left {
  position: absolute;
  right: auto;
  bottom: 0;
  height: 100%;
  width: 3px;
  border-radius: $card-border-radius 0 0 $card-border-radius;
}

.card-status-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  top: initial;
  height: 3px;
  border-radius: 0 0 $card-border-radius $card-border-radius;
}

/**
Card table
 */
.card-table {
  margin-bottom: 0;

  tr {
    &:first-child {
      td,
      th {
        border-top: 0;
      }
    }

    td,
    th {
      border-top: 1px solid $border-color;

      &:first-child {
        padding-left: $card-spacer-x;
      }

      &:last-child {
        padding-right: $card-spacer-x;
      }
    }
  }

  .card-body + & {
    border-top: 1px solid $border-color;
  }
}

/*
Card code
 */
.card-code {
  background: $dark;

  .highlight {
    margin: 0;
  }

  pre {
    padding: 0;
    margin: 0;
    color: #fff;
    text-shadow: none;
    background: transparent;
  }
}

/*
Card chart
 */
.card-chart {
  position: relative;
  z-index: 1;
  height: 3.5rem;
}

/*
Card Aside
*/
.card-aside {
  flex-direction: row;
}

.card-aside-column {
  min-width: 5rem;
  width: 30%;
  flex: 0 0 30%;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background: no-repeat center/cover;
}

/*
Card profile
 */
.card-profile {
  .card-header {
    height: 9rem;
    background-size: cover;
  }
}

.card-profile-img {
  max-width: 6rem;
  margin-top: -5rem;
  margin-bottom: 1rem;
  border: 3px solid #fff;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

/*
Card list group
 */
.card-list-group {
  .card-body + & {
    border-top: 1px solid $border-color;
  }

  .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;

    padding-left: $card-spacer-x;
    padding-right: $card-spacer-x;

    &:last-child {
      border-bottom: 0;
    }

    &:first-child {
      border-top: 0;
    }
  }
}
