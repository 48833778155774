// stylelint-disable declaration-no-important

.max-h-full {
  max-height: 100% !important;
}

.max-h-screen {
  max-height: 100vh !important;
}

.max-w-full {
  max-width: 100% !important;
}

.max-w-screen {
  max-width: 100vw !important;
}

.min-h-0 {
  min-height: 0 !important;
}

.min-h-full {
  min-height: 100% !important;
}

.min-h-screen {
  min-height: 100vh !important;
}

.min-w-0 {
  min-width: 0 !important;
}

.min-w-full {
  min-width: 100% !important;
}

@each $size-name, $size in $sizes-percentage {
  .w-#{$size-name}p {
    width: $size !important;
  }
  .h-#{$size-name}p {
    height: $size !important;
  }
}

@each $size-name, $size in map_merge($spacers, $size-spacers) {
  .w-#{$size-name} {
    width: $size !important;
  }
  .h-#{$size-name} {
    height: $size !important;
  }
}

.h-screen {
  height: 100vh !important;
}

.w-screen {
  width: 100vw !important;
}

@each $size-name, $size in $size-max-spacers {
  .max-w-#{$size-name} {
    max-width: $size !important;
  }
  .max-h-#{$size-name} {
    max-height: $size !important;
  }
}
