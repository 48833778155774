.list-timeline {
  position: relative;
  padding: 0;
  margin: 0;
  list-style: none;
}

.list-timeline > li {
  position: relative;
  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.list-timeline-time {
  float: right;
  margin-left: 1rem;
  color: $text-muted;
}

.list-timeline-icon {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  //width: .5rem;
  //height: .5rem;
  color: #fff;
  text-align: center;
  background: $text-muted-light;
  border-radius: 50%;

  .icon {
    width: 1rem;
    height: 1rem;
  }
}

.list-timeline-title {
  margin: 0;
  font-weight: 600;
}

.list-timeline-content {
  margin-left: 3.5rem;
}

@media screen and (min-width: 768px) {
  .list-timeline:not(.list-timeline-simple) {
    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc(7.5rem + 2px);
      z-index: 1;
      display: block;
      width: 4px;
      content: '';
      background-color: $body-bg;
    }

    & > li {
      z-index: 2;
      min-height: 40px;
    }

    .list-timeline-time {
      position: absolute;
      top: 0.5rem;
      right: 0;
      width: 5.5rem;
      margin: 0;
      text-align: right;
    }

    .list-timeline-icon {
      top: 0;
      left: 6.5rem;
    }

    .list-timeline-content {
      padding: 0.625rem 0 0 10rem;
      margin: 0;
    }
  }
}

.list-group-transparent {
  .list-group-item-heading {
    cursor: pointer;
    margin-top: 1rem;
    font-style: italic;
    font-weight: 200;
    text-transform: uppercase;
  }

  .list-group-item {
    padding: 0.5rem 1rem;
    background: none;
    border: 0;
    border-radius: 3px;
    cursor: pointer;
    overflow: hidden;

    .icon {
      color: $text-muted-light;
    }

    &:hover:not(.active) {
      // font-weight: 600;
      color: $primary;
      background: rgba($primary, 0.16);
    }

    &.active {
      // font-weight: 600;
      color: $primary;
      background: rgba($primary, 0.06);

      .icon {
        color: inherit;
      }
    }
  }
}

.list-separated-item {
  padding: 1rem 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  & + & {
    border-top: 1px solid $border-color;
  }
}
