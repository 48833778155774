.navbar {
  background: #fff;
  color: $body-color;
  border-bottom: 1px solid $border-color;
  padding: 0;

  .container {
    justify-content: start;
  }

  .nav-item:not(:last-child) {
    margin-right: 1.4rem;
  }

  .nav-link {
    display: flex;
    align-items: center;
    color: inherit;
    padding: 1rem 0rem;
    border-bottom: 1px solid transparent;
    padding-left: 0 !important;
    padding-right: 0 !important;
    color: $text-muted;
    height: 100%;

    .nav-icon {
      margin-right: 0.2rem;
    }

    &:hover {
      color: $body-color;
    }

    &.active {
      border-bottom-color: $primary;
      color: inherit;
    }
  }
}

.navbar-nav {
  padding-left: 12px;
}
