.toolbar {
  display: flex;
  flex-wrap: nowrap;
  flex-shrink: 0;
  margin: 0 -0.5rem;

  > * {
    margin: 0 0.5rem;
  }
}
