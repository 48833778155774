.pace {
  pointer-events: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  position: fixed;
  top: 0;
  right: 100%;
  z-index: 2000;
  width: 100%;
  height: 2px;
  background: $primary;
}
