.container {
  @include make-container-breakpoints();
}

.row-deck {
  > .col,
  > [class*='col-'] {
    display: flex;
    align-items: stretch;

    .card {
      flex: 1 1 auto;
    }
  }
}

@each $name, $value in (0: 0, xs: 0.25rem, sm: 0.5rem, lg: 1rem, xl: 1.5rem) {
  .row-#{$name} {
    margin-right: (-$value);
    margin-left: -($value);

    > .col,
    > [class*='col-'] {
      padding-right: $value;
      padding-left: $value;
    }

    .card {
      margin-bottom: 2 * $value;
    }
  }
}

.row-deck {
  > .col,
  > [class*='col-'] {
    display: flex;
    align-items: stretch;

    .card {
      flex: 1 1 auto;
    }
  }
}
