.nav-tabs {
  .icon {
    color: $text-muted;
  }

  .nav-link {
    padding-right: $card-spacer-x;
    padding-left: $card-spacer-x;
    line-height: 20px;
    color: $text-muted-light;
    cursor: pointer;
    background: transparent;
    border: none;
    border-bottom: 1px solid transparent;
    transition: 0.3s color, 0.3s border-color;

    &:hover {
      color: $body-color;
      border-color: transparent;
    }

    &.disabled {
      color: rgba($text-muted, 0.2);
    }

    &.active {
      background: inherit;
      border-color: transparent;
      border-bottom-color: $primary;
    }
  }
}

.nav-tabs-alt {
  .nav-link {
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
  }
}
