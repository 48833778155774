// stylelint-disable property-no-vendor-prefix

:root {
  font-size: 15px;
}

html {
  height: 100%;
  font-size: 14px;

  @include media-breakpoint-down(md) {
    font-size: 15px;
  }
}

body {
  overflow-y: scroll;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: none;
  font-feature-settings: 'liga' 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body *::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  transition: 0.3s background;
}

body *::-webkit-scrollbar-thumb {
  background: $gray-400;
}

body *:hover::-webkit-scrollbar-thumb {
  background: $gray-500;
}

/**
Remove the cancel buttons in Chrome and Safari on macOS.
 */
[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

#page-content {
  flex: 1 0 auto;
}
