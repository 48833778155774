.lead {
  line-height: 1.4;
}

a {
  text-decoration-skip-ink: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  a {
    color: inherit;
  }
}

strong,
b {
  font-weight: 600;
}

blockquote {
  padding-left: 1rem;
  color: $text-muted;
  border-left: 2px solid $border-color;

  p {
    margin-bottom: 1rem;
  }

  cite {
    display: block;
    text-align: right;

    &::before {
      content: '— ';
    }
  }
}

code {
  padding: 3px;
  background: rgba(0, 0, 0, 0.025);
  border: 1px solid rgba(#000, 0.05);
  border-radius: $border-radius;

  pre & {
    padding: 0;
    background: none;
    border: none;
    border-radius: 0;
  }
}

hr,
.hr {
  margin: 2rem auto;
  border-top-color: $border-color;
}

.hr-dash {
  border-top-style: dashed;
}

.hr-dot {
  border-top-style: dotted;
}

pre {
  padding: 1rem;
  overflow: auto;
  font-size: 85%;
  hyphens: none;
  line-height: 1.45;
  color: $gray-800;
  text-shadow: 0 1px #fff;
  tab-size: 3;
  background-color: #f8fafc;
  border-radius: $border-radius;
}

img {
  max-width: 100%;
}

/**
Divider
 */
.divider {
  display: flex;
  flex: 0;
  align-items: center;
  width: 100%;
  margin: 1.5rem auto;
  font-size: $h6-font-size;
  color: $text-muted;
  letter-spacing: 0.5px;
  font-weight: 400;

  &::before,
  &::after {
    flex-grow: 1;
    content: '';
    border-top: 1px solid $border-color;
  }

  &::before {
    margin-right: 1rem;
  }

  &::after {
    margin-left: 1rem;
  }
}

/**
Selection
 */
::selection {
  color: #fff;
  background-color: lighten($primary, 8%);
}
