.max-w-1\/12 {
  max-width: 8.333333333333332% !important;
}
.basis-1\/12 {
  flex-basis: 8.333333333333332% !important;
}

.max-w-2\/12 {
  max-width: 16.666666666666664% !important;
}
.basis-2\/12 {
  flex-basis: 16.666666666666664% !important;
}

.max-w-3\/12 {
  max-width: 25% !important;
}
.basis-3\/12 {
  flex-basis: 25% !important;
}

.max-w-4\/12 {
  max-width: 33.33333333333333% !important;
}
.basis-4\/12 {
  flex-basis: 33.33333333333333% !important;
}

.max-w-5\/12 {
  max-width: 41.66666666666667% !important;
}
.basis-5\/12 {
  flex-basis: 41.66666666666667% !important;
}

.max-w-6\/12 {
  max-width: 50% !important;
}
.basis-6\/12 {
  flex-basis: 50% !important;
}

.max-w-7\/12 {
  max-width: 58.333333333333336% !important;
}
.basis-7\/12 {
  flex-basis: 58.333333333333336% !important;
}

.max-w-8\/12 {
  max-width: 66.66666666666666% !important;
}
.basis-8\/12 {
  flex-basis: 66.66666666666666% !important;
}

.max-w-9\/12 {
  max-width: 75% !important;
}
.basis-9\/12 {
  flex-basis: 75% !important;
}

.max-w-10\/12 {
  max-width: 83.33333333333334% !important;
}
.basis-10\/12 {
  flex-basis: 83.33333333333334% !important;
}

.max-w-11\/12 {
  max-width: 91.66666666666666% !important;
}
.basis-11\/12 {
  flex-basis: 91.66666666666666% !important;
}

.max-w-12\/12 {
  max-width: 100% !important;
}
.basis-12\/12 {
  flex-basis: 100% !important;
}

@media (min-width: 0) {
  .xs\:max-w-1\/12 {
    max-width: 8.333333333333332% !important;
  }
  .xs\:basis-1\/12 {
    flex-basis: 8.333333333333332% !important;
  }
}

@media (min-width: 0) {
  .xs\:max-w-2\/12 {
    max-width: 16.666666666666664% !important;
  }
  .xs\:basis-2\/12 {
    flex-basis: 16.666666666666664% !important;
  }
}

@media (min-width: 0) {
  .xs\:max-w-3\/12 {
    max-width: 25% !important;
  }
  .xs\:basis-3\/12 {
    flex-basis: 25% !important;
  }
}

@media (min-width: 0) {
  .xs\:max-w-4\/12 {
    max-width: 33.33333333333333% !important;
  }
  .xs\:basis-4\/12 {
    flex-basis: 33.33333333333333% !important;
  }
}

@media (min-width: 0) {
  .xs\:max-w-5\/12 {
    max-width: 41.66666666666667% !important;
  }
  .xs\:basis-5\/12 {
    flex-basis: 41.66666666666667% !important;
  }
}

@media (min-width: 0) {
  .xs\:max-w-6\/12 {
    max-width: 50% !important;
  }
  .xs\:basis-6\/12 {
    flex-basis: 50% !important;
  }
}

@media (min-width: 0) {
  .xs\:max-w-7\/12 {
    max-width: 58.333333333333336% !important;
  }
  .xs\:basis-7\/12 {
    flex-basis: 58.333333333333336% !important;
  }
}

@media (min-width: 0) {
  .xs\:max-w-8\/12 {
    max-width: 66.66666666666666% !important;
  }
  .xs\:basis-8\/12 {
    flex-basis: 66.66666666666666% !important;
  }
}

@media (min-width: 0) {
  .xs\:max-w-9\/12 {
    max-width: 75% !important;
  }
  .xs\:basis-9\/12 {
    flex-basis: 75% !important;
  }
}

@media (min-width: 0) {
  .xs\:max-w-10\/12 {
    max-width: 83.33333333333334% !important;
  }
  .xs\:basis-10\/12 {
    flex-basis: 83.33333333333334% !important;
  }
}

@media (min-width: 0) {
  .xs\:max-w-11\/12 {
    max-width: 91.66666666666666% !important;
  }
  .xs\:basis-11\/12 {
    flex-basis: 91.66666666666666% !important;
  }
}

@media (min-width: 0) {
  .xs\:max-w-12\/12 {
    max-width: 100% !important;
  }
  .xs\:basis-12\/12 {
    flex-basis: 100% !important;
  }
}

@media (min-width: 576px) {
  .sm\:max-w-1\/12 {
    max-width: 8.333333333333332% !important;
  }
  .sm\:basis-1\/12 {
    flex-basis: 8.333333333333332% !important;
  }
}

@media (min-width: 576px) {
  .sm\:max-w-2\/12 {
    max-width: 16.666666666666664% !important;
  }
  .sm\:basis-2\/12 {
    flex-basis: 16.666666666666664% !important;
  }
}

@media (min-width: 576px) {
  .sm\:max-w-3\/12 {
    max-width: 25% !important;
  }
  .sm\:basis-3\/12 {
    flex-basis: 25% !important;
  }
}

@media (min-width: 576px) {
  .sm\:max-w-4\/12 {
    max-width: 33.33333333333333% !important;
  }
  .sm\:basis-4\/12 {
    flex-basis: 33.33333333333333% !important;
  }
}

@media (min-width: 576px) {
  .sm\:max-w-5\/12 {
    max-width: 41.66666666666667% !important;
  }
  .sm\:basis-5\/12 {
    flex-basis: 41.66666666666667% !important;
  }
}

@media (min-width: 576px) {
  .sm\:max-w-6\/12 {
    max-width: 50% !important;
  }
  .sm\:basis-6\/12 {
    flex-basis: 50% !important;
  }
}

@media (min-width: 576px) {
  .sm\:max-w-7\/12 {
    max-width: 58.333333333333336% !important;
  }
  .sm\:basis-7\/12 {
    flex-basis: 58.333333333333336% !important;
  }
}

@media (min-width: 576px) {
  .sm\:max-w-8\/12 {
    max-width: 66.66666666666666% !important;
  }
  .sm\:basis-8\/12 {
    flex-basis: 66.66666666666666% !important;
  }
}

@media (min-width: 576px) {
  .sm\:max-w-9\/12 {
    max-width: 75% !important;
  }
  .sm\:basis-9\/12 {
    flex-basis: 75% !important;
  }
}

@media (min-width: 576px) {
  .sm\:max-w-10\/12 {
    max-width: 83.33333333333334% !important;
  }
  .sm\:basis-10\/12 {
    flex-basis: 83.33333333333334% !important;
  }
}

@media (min-width: 576px) {
  .sm\:max-w-11\/12 {
    max-width: 91.66666666666666% !important;
  }
  .sm\:basis-11\/12 {
    flex-basis: 91.66666666666666% !important;
  }
}

@media (min-width: 576px) {
  .sm\:max-w-12\/12 {
    max-width: 100% !important;
  }
  .sm\:basis-12\/12 {
    flex-basis: 100% !important;
  }
}

@media (min-width: 768px) {
  .md\:max-w-1\/12 {
    max-width: 8.333333333333332% !important;
  }
  .md\:basis-1\/12 {
    flex-basis: 8.333333333333332% !important;
  }
}

@media (min-width: 768px) {
  .md\:max-w-2\/12 {
    max-width: 16.666666666666664% !important;
  }
  .md\:basis-2\/12 {
    flex-basis: 16.666666666666664% !important;
  }
}

@media (min-width: 768px) {
  .md\:max-w-3\/12 {
    max-width: 25% !important;
  }
  .md\:basis-3\/12 {
    flex-basis: 25% !important;
  }
}

@media (min-width: 768px) {
  .md\:max-w-4\/12 {
    max-width: 33.33333333333333% !important;
  }
  .md\:basis-4\/12 {
    flex-basis: 33.33333333333333% !important;
  }
}

@media (min-width: 768px) {
  .md\:max-w-5\/12 {
    max-width: 41.66666666666667% !important;
  }
  .md\:basis-5\/12 {
    flex-basis: 41.66666666666667% !important;
  }
}

@media (min-width: 768px) {
  .md\:max-w-6\/12 {
    max-width: 50% !important;
  }
  .md\:basis-6\/12 {
    flex-basis: 50% !important;
  }
}

@media (min-width: 768px) {
  .md\:max-w-7\/12 {
    max-width: 58.333333333333336% !important;
  }
  .md\:basis-7\/12 {
    flex-basis: 58.333333333333336% !important;
  }
}

@media (min-width: 768px) {
  .md\:max-w-8\/12 {
    max-width: 66.66666666666666% !important;
  }
  .md\:basis-8\/12 {
    flex-basis: 66.66666666666666% !important;
  }
}

@media (min-width: 768px) {
  .md\:max-w-9\/12 {
    max-width: 75% !important;
  }
  .md\:basis-9\/12 {
    flex-basis: 75% !important;
  }
}

@media (min-width: 768px) {
  .md\:max-w-10\/12 {
    max-width: 83.33333333333334% !important;
  }
  .md\:basis-10\/12 {
    flex-basis: 83.33333333333334% !important;
  }
}

@media (min-width: 768px) {
  .md\:max-w-11\/12 {
    max-width: 91.66666666666666% !important;
  }
  .md\:basis-11\/12 {
    flex-basis: 91.66666666666666% !important;
  }
}

@media (min-width: 768px) {
  .md\:max-w-12\/12 {
    max-width: 100% !important;
  }
  .md\:basis-12\/12 {
    flex-basis: 100% !important;
  }
}

@media (min-width: 992px) {
  .lg\:max-w-1\/12 {
    max-width: 8.333333333333332% !important;
  }
  .lg\:basis-1\/12 {
    flex-basis: 8.333333333333332% !important;
  }
}

@media (min-width: 992px) {
  .lg\:max-w-2\/12 {
    max-width: 16.666666666666664% !important;
  }
  .lg\:basis-2\/12 {
    flex-basis: 16.666666666666664% !important;
  }
}

@media (min-width: 992px) {
  .lg\:max-w-3\/12 {
    max-width: 25% !important;
  }
  .lg\:basis-3\/12 {
    flex-basis: 25% !important;
  }
}

@media (min-width: 992px) {
  .lg\:max-w-4\/12 {
    max-width: 33.33333333333333% !important;
  }
  .lg\:basis-4\/12 {
    flex-basis: 33.33333333333333% !important;
  }
}

@media (min-width: 992px) {
  .lg\:max-w-5\/12 {
    max-width: 41.66666666666667% !important;
  }
  .lg\:basis-5\/12 {
    flex-basis: 41.66666666666667% !important;
  }
}

@media (min-width: 992px) {
  .lg\:max-w-6\/12 {
    max-width: 50% !important;
  }
  .lg\:basis-6\/12 {
    flex-basis: 50% !important;
  }
}

@media (min-width: 992px) {
  .lg\:max-w-7\/12 {
    max-width: 58.333333333333336% !important;
  }
  .lg\:basis-7\/12 {
    flex-basis: 58.333333333333336% !important;
  }
}

@media (min-width: 992px) {
  .lg\:max-w-8\/12 {
    max-width: 66.66666666666666% !important;
  }
  .lg\:basis-8\/12 {
    flex-basis: 66.66666666666666% !important;
  }
}

@media (min-width: 992px) {
  .lg\:max-w-9\/12 {
    max-width: 75% !important;
  }
  .lg\:basis-9\/12 {
    flex-basis: 75% !important;
  }
}

@media (min-width: 992px) {
  .lg\:max-w-10\/12 {
    max-width: 83.33333333333334% !important;
  }
  .lg\:basis-10\/12 {
    flex-basis: 83.33333333333334% !important;
  }
}

@media (min-width: 992px) {
  .lg\:max-w-11\/12 {
    max-width: 91.66666666666666% !important;
  }
  .lg\:basis-11\/12 {
    flex-basis: 91.66666666666666% !important;
  }
}

@media (min-width: 992px) {
  .lg\:max-w-12\/12 {
    max-width: 100% !important;
  }
  .lg\:basis-12\/12 {
    flex-basis: 100% !important;
  }
}

@media (min-width: 1280px) {
  .xl\:max-w-1\/12 {
    max-width: 8.333333333333332% !important;
  }
  .xl\:basis-1\/12 {
    flex-basis: 8.333333333333332% !important;
  }
}

@media (min-width: 1280px) {
  .xl\:max-w-2\/12 {
    max-width: 16.666666666666664% !important;
  }
  .xl\:basis-2\/12 {
    flex-basis: 16.666666666666664% !important;
  }
}

@media (min-width: 1280px) {
  .xl\:max-w-3\/12 {
    max-width: 25% !important;
  }
  .xl\:basis-3\/12 {
    flex-basis: 25% !important;
  }
}

@media (min-width: 1280px) {
  .xl\:max-w-4\/12 {
    max-width: 33.33333333333333% !important;
  }
  .xl\:basis-4\/12 {
    flex-basis: 33.33333333333333% !important;
  }
}

@media (min-width: 1280px) {
  .xl\:max-w-5\/12 {
    max-width: 41.66666666666667% !important;
  }
  .xl\:basis-5\/12 {
    flex-basis: 41.66666666666667% !important;
  }
}

@media (min-width: 1280px) {
  .xl\:max-w-6\/12 {
    max-width: 50% !important;
  }
  .xl\:basis-6\/12 {
    flex-basis: 50% !important;
  }
}

@media (min-width: 1280px) {
  .xl\:max-w-7\/12 {
    max-width: 58.333333333333336% !important;
  }
  .xl\:basis-7\/12 {
    flex-basis: 58.333333333333336% !important;
  }
}

@media (min-width: 1280px) {
  .xl\:max-w-8\/12 {
    max-width: 66.66666666666666% !important;
  }
  .xl\:basis-8\/12 {
    flex-basis: 66.66666666666666% !important;
  }
}

@media (min-width: 1280px) {
  .xl\:max-w-9\/12 {
    max-width: 75% !important;
  }
  .xl\:basis-9\/12 {
    flex-basis: 75% !important;
  }
}

@media (min-width: 1280px) {
  .xl\:max-w-10\/12 {
    max-width: 83.33333333333334% !important;
  }
  .xl\:basis-10\/12 {
    flex-basis: 83.33333333333334% !important;
  }
}

@media (min-width: 1280px) {
  .xl\:max-w-11\/12 {
    max-width: 91.66666666666666% !important;
  }
  .xl\:basis-11\/12 {
    flex-basis: 91.66666666666666% !important;
  }
}

@media (min-width: 1280px) {
  .xl\:max-w-12\/12 {
    max-width: 100% !important;
  }
  .xl\:basis-12\/12 {
    flex-basis: 100% !important;
  }
}

.hidden {
  display: none !important;
}

.flex {
  display: flex !important;
}

.-mx-3 {
  margin-left: -0.75rem !important;
  margin-right: -0.75rem !important;
}

.shrink-0 {
  flex-shrink: 0 !important;
}

.grow-0 {
  flex-grow: 0 !important;
}

.basis-full {
    flex-basis: 100% !important;
}

.max-w-full {
    max-width: 100% !important;
}

.px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.mb-6 {
    margin-bottom: 1.5rem !important;
}

.pb-4 {
    padding-bottom: 1rem !important;
}

.justify-between {
    justify-content: space-between !important;
}