.dropdown-divider {
  border-color: $border-color;
}

.dropdown-menu-scrollable {
  height: auto;
  max-height: 12rem;
  overflow-x: hidden;
}

.dropdown-menu {
  min-width: 12rem;
  box-shadow: $box-shadow;
}

.dropdown-item {
  color: inherit;

  &.active {
    font-weight: 600;
    color: $primary;
    background-color: rgba(32, 107, 196, 0.06);
  }
}

.dropdown-menu-arrow {
  margin-top: 0.75rem;

  &::before {
    position: absolute;
    top: -6px;
    left: 12px;
    display: inline-block;
    content: '';
    border-right: 5px solid transparent;
    border-bottom: 5px solid $border-color;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    border-left: 5px solid transparent;
  }

  &::after {
    position: absolute;
    top: -5px;
    left: 12px;
    display: inline-block;
    content: '';
    border-right: 5px solid transparent;
    border-bottom: 5px solid #fff;
    border-left: 5px solid transparent;
  }

  &.dropdown-menu-right {
    &::before,
    &::after {
      right: 12px;
      left: auto;
    }
  }
}

.dropdown-icon {
  display: inline-block;
  width: 1em;
  margin-right: 0.5rem;
  margin-left: -0.5rem;
  color: $text-muted;
  text-align: center;
  vertical-align: -1px;
  stroke-width: 1.75px;
}
